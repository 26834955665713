<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              <div></div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买方">
              <div></div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="卖方">
              <div></div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单类型"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单总额">
              元
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单状态"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交付周期"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              <div></div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              <div></div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="commodityLoading"
        :pagination="false"
        rowKey=""
      ></a-table>
    </section>
    <section class="card__container">
      <div class="container__title">合同信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8"></a-col>
          <a-col :span="8">
            <a-form-item label="签订时间"></a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">付款信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8" label="付款机构"></a-col>
          <a-col :span="8">
            <a-form-item label="收款机构"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="付款金额"></a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="付款时间"></a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="状态"></a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";

const commodityColumns = [
  {
    title: "商品名称",
    dataIndex: "",
    width: ""
  },
  {
    title: "商品编号",
    dataIndex: "",
    width: ""
  },
  {
    title: "商品图",
    dataIndex: "",
    width: ""
  },
  {
    title: "出厂单价（元）",
    dataIndex: "",
    width: ""
  },
  {
    title: "商品数量",
    dataIndex: "",
    width: ""
  },
  {
    title: "已发货数量",
    dataIndex: "",
    width: ""
  },
  {
    title: "出厂总价（元）",
    dataIndex: "",
    width: ""
  }
];

export default {
  name: "DeliverOrderDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      commodityData: [],
      commodityColumns,
      commodityLoading: false
    };
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 72px !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.commodity__table {
  margin-top: 20px;
}
</style>
